import { getCommonParamsOptions } from '@/utils/common'

export const searchFormConfig = {
  formItems: [
    {
      field: 'remoteIp',
      type: 'input',
      label: 'terminal.remote-ip'
    },
    {
      field: 'sn',
      type: 'input',
      label: 'terminal.sn'
    },
    // {
    //   field: 'operator',
    //   type: 'input',
    //   label: 'general.operator'
    // },
    {
      field: 'status',
      type: 'select',
      label: 'common.app-status',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('remote_status')
    },
    {
      field: 'createTime',
      type: 'datepicker',
      label: 'general.creation-date',
      otherOptions: {
        type: 'daterange'
      },
      dateFieldName: ['startDateTime', 'endDateTime']
    }
  ]
}
