import { getCommonParamsItemLabel } from '@/utils/common'

export const contentTableConfig = {
  // title: '('param.app-application-list')',
  propList: [
    {
      prop: 'remoteIp',
      label: 'terminal.remote-ip'
    },
    {
      prop: 'sn',
      label: 'terminal.sn',
      width: '150'
    },
    {
      prop: 'desktopSession',
      label: 'terminal.desktop-session'
    },
    // {
    //   prop: 'operator',
    //   label: 'general.operator'
    // },
    {
      prop: 'description',
      label: 'general.description'
    },
    {
      prop: 'status',
      label: 'common.app-status',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'remote_status')
      }
    },
    // {
    //   prop: 'createdBy',
    //   label: 'general.creation-user'
    // },
    {
      prop: 'startTime',
      label: 'general.start-time'
    },
    {
      prop: 'endTime',
      label: 'general.end-time'
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time'
    }
    // {
    //   label: 'common.app-operation',
    //   width: '300',
    //   slotName: 'handler'
    // }
  ],
  showIndexColumn: true
}
